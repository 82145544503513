*,
*::after,
*::before {
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
}

html {
  font-size: 18px;
  color: var(--color);
}

body {
  padding: 0;
  margin: 0;
  display: block;
  background-color: var(--background);
}
