:root {
  --background: #ffffff;
  --color: #121212;
  --orange: #f59327;
  --orange-light: hsl(31, 91%, 76%);
  --blue: #4993fa;
  --text: #283149;
  --light-background: #5e5e5e;

  //   @media (prefers-color-scheme: dark) {
  //     --background: #3b3b3b;
  //     --color: #ffffff;
  //   }
}
