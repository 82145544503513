.page {
  width: 90%;
  max-width: 900px;
  margin: auto;

  &.full {
    max-width: none;
    width: 100%;

    article {
      width: 90%;
      max-width: 1140px;
      margin: auto;
    }
  }

  header {
    text-align: left;
    padding: 30px 0;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 90px);

    .inner {
      text-align: center;

      h1 {
        font-size: 10em;
      }
    }
  }
}

.page-content {
  width: 90%;
  margin: auto;
  max-width: 1000px;
  height: auto;

  &.full {
    width: 100%;
    max-width: unset;
  }
}
