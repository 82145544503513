h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  font-size: 1em;
  line-height: 1;
}

h1 {
  font-size: 4em;
  font-weight: 300;
}

h2 {
  font-size: 3em;
  font-weight: 300;
}

h3 {
  font-size: 2em;
  font-weight: 300;
}

h4 {
  font-size: 1.5em;
  font-weight: 400;
}

h5 {
  font-size: 1.25em;
  font-weight: 300;
}

h6 {
  font-size: 1.1em;
  font-weight: 600;
}

p {
  font-size: 1em;
  font-weight: 300;
  margin-bottom: 0.7em;
}
