@import './vars/colors';
@import './util/resets';
@import './util/typography';
@import './layout/page';

::-moz-selection {
  background-color: var(--orange);
  color: var(--background);
  border-radius: 8px;
}

::selection {
  background-color: var(--orange);
  color: var(--background);
  border-radius: 8px;
}

footer {
  width: 100%;
  padding: 20px;

  .info {
    display: flex;
    align-items: center;
    padding: 30px 0;
    width: 90%;
    max-width: 1000px;
    margin: auto;

    .sitemap,
    .map {
      flex: 1;
    }

    .sitemap {
      list-style: none;
      margin: 0;
      padding: 0 30px 0 0;
      text-transform: uppercase;

      li {
        padding-bottom: 10px;
        color: var(--color);

        a {
          color: var(--color);
          text-decoration: none;
          font-weight: 300;

          &:hover {
            color: #f59327;
          }
        }
      }
    }

    .map {
      max-width: 200px;
      will-change: filter;
      transition: filter cubic-bezier(0.455, 0.03, 0.515, 0.955) 300ms;
      text-decoration: none;

      .image {
        width: 100%;

        img {
          width: 100%;
          display: block;
        }
      }

      address {
        display: block;
        padding-top: 5px;
        font-style: normal;
        font-weight: 300;
        color: var(--text);
      }

      &:hover {
        filter: hue-rotate(30deg);
      }
    }
  }

  .copyright {
    padding-top: 15px;
    text-align: center;
    font-weight: 400;
    color: var(--color);
    z-index: 100;
  }
}

.notice-banner {
  display: block;
  width: 100%;
  background-color: var(--orange);
  position: sticky;
  bottom: -1px;
  left: 0;
  text-align: center;
  z-index: 8;
  padding: 20px;
  color: var(--background);
  z-index: 100;

  a {
    color: var(--background);
    text-decoration: underline;

    &:hover {
      color: var(--text);
    }
  }

  @media screen and (max-width: 450px) {
    position: static;
  }
}

input {
  outline: none;
}

/* clears the 'X' from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

p {
  line-height: 2;
}
